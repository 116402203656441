.board {
    perspective: calc(929px * var(--device-pixel-ratio));
    width: calc(1010px * var(--device-pixel-ratio));
    margin: 0 auto calc(20px * var(--device-pixel-ratio));
    position: relative;
    height: calc(150px * var(--device-pixel-ratio));
    &-info {
        transition: transform 0.5s, margin 0.5s;
        transform: rotate3d(0, 0, 0, 0deg);
        transform-origin: bottom;
        &.threeD {
            transform: rotate3d(-10, 0, 0, -45deg);
            transform-origin: bottom;
        }
        &-sides {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: calc(54px * var(--device-pixel-ratio));
            margin-bottom: calc(30px * var(--device-pixel-ratio));
            & .title {
                font: 700 calc(34px * var(--device-pixel-ratio))/calc(44px * var(--device-pixel-ratio)) "SF Pro Text Light";
                letter-spacing: calc(1.48px * var(--device-pixel-ratio));
                color: #FFFFFF;
                text-transform: uppercase;
                background: transparent linear-gradient(180deg, rgba(29, 27, 45, 0.7019607843) 0%, rgba(29, 27, 45, 0.9019607843) 100%) 0% 0% no-repeat padding-box;
                border: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
                border-radius: 8px;
                padding: calc(10px * var(--device-pixel-ratio)) calc(15px * var(--device-pixel-ratio));
            }
        }
    }
    .book-show-bottom {
        bottom: 0;
        position: absolute;
        width: 100%;
        .player-8-book {
            border-radius: 12px;
            bottom: -10px;
            color: #fff;
            font-size: calc(12px*var(--device-pixel-ratio));
            left: 7.5%;
            min-width: calc(64px*var(--device-pixel-ratio));
            padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
            position: absolute;
            text-align: center;
            &.green {
                background: transparent linear-gradient(180deg, rgba(70, 174, 179, 0.7), rgba(56, 50, 94, 0.9)) 0 0 no-repeat padding-box;
                color: rgba(50, 215, 75, 1);
            }
            &.red {
                background: transparent linear-gradient(180deg, rgba(70, 174, 179, 0.7), rgba(56, 50, 94, 0.9)) 0 0 no-repeat padding-box;
                color: rgba(255, 69, 58, 1);
            }
        }
        .player-9-book {
            border-radius: 12px;
            bottom: -10px;
            color: #fff;
            font-size: calc(12px*var(--device-pixel-ratio));
            left: 31.5%;
            min-width: calc(64px*var(--device-pixel-ratio));
            padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
            position: absolute;
            text-align: center;
            &.green {
                background: transparent linear-gradient(180deg, rgba(70, 174, 179, 0.7), rgba(56, 50, 94, 0.9)) 0 0 no-repeat padding-box;
                color: rgba(50, 215, 75, 1);
            }
            &.red {
                background: transparent linear-gradient(180deg, rgba(70, 174, 179, 0.7), rgba(56, 50, 94, 0.9)) 0 0 no-repeat padding-box;
                color: rgba(255, 69, 58, 1);
            }
        }
        .player-10-book {
            border-radius: 12px;
            bottom: -10px;
            color: #fff;
            font-size: calc(12px*var(--device-pixel-ratio));
            right: 36%;
            min-width: calc(64px*var(--device-pixel-ratio));
            padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
            position: absolute;
            text-align: center;
            &.green {
                background: transparent linear-gradient(180deg, rgba(70, 174, 179, 0.7), rgba(56, 50, 94, 0.9)) 0 0 no-repeat padding-box;
                color: rgba(50, 215, 75, 1);
            }
            &.red {
                background: transparent linear-gradient(180deg, rgba(70, 174, 179, 0.7), rgba(56, 50, 94, 0.9)) 0 0 no-repeat padding-box;
                color: rgba(255, 69, 58, 1);
            }
        }
        .player-11-book {
            border-radius: 12px;
            bottom: -10px;
            color: #fff;
            font-size: calc(12px*var(--device-pixel-ratio));
            right: 8.5%;
            min-width: calc(64px*var(--device-pixel-ratio));
            padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
            position: absolute;
            text-align: center;
            &.green {
                background: transparent linear-gradient(180deg, rgba(70, 174, 179, 0.7), rgba(56, 50, 94, 0.9)) 0 0 no-repeat padding-box;
                color: rgba(50, 215, 75, 1);
            }
            &.red {
                background: transparent linear-gradient(180deg, rgba(70, 174, 179, 0.7), rgba(56, 50, 94, 0.9)) 0 0 no-repeat padding-box;
                color: rgba(255, 69, 58, 1);
            }
        }
    }
    &-svg {
        &.smallBoard {
            display: none;
        }
        &-item {
            cursor: pointer;
            &.disabled {
                pointer-events: none;
                opacity: 0.4;
            }
            &:hover {
                &.lay {
                    & g {
                        stroke: #faa9ba;
                        fill: #faa9ba;
                    }
                }
                &.back {
                    & g {
                        stroke: #72bbef;
                        fill: #72bbef;
                    }
                }
                &.pair {
                    & g {
                        stroke: #eaeaec;
                        fill: #eaeaec;
                    }
                }
            }
            &.active,
            &:active {
                &.lay {
                    & g {
                        stroke: #faa9ba;
                        fill: #faa9ba;
                    }
                }
                &.back {
                    & g {
                        stroke: #72bbef;
                        fill: #72bbef;
                    }
                }
                &.pair {
                    & g {
                        stroke: #eaeaec;
                        fill: #eaeaec;
                    }
                }
            }
        }
    }
}

.middlePc {
    .board {
        width: calc(900px * var(--device-pixel-ratio));
        height: calc(130px * var(--device-pixel-ratio));
        &-info {
            &-sides {
                height: calc(40px * var(--device-pixel-ratio));
                & .title {
                    font: 700 calc(22px * var(--device-pixel-ratio))/calc(30px * var(--device-pixel-ratio)) "SF Pro Text Light";
                    letter-spacing: calc(1.28px * var(--device-pixel-ratio));
                    padding: calc(5px * var(--device-pixel-ratio)) calc(10px * var(--device-pixel-ratio));
                }
            }
        }
    }
}

.smallPc {
    .board {
        width: calc(700px * var(--device-pixel-ratio));
        height: calc(110px * var(--device-pixel-ratio));
        margin: 0 calc(11px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio)) calc(11px * var(--device-pixel-ratio));
        &-info {
            &-sides {
                height: calc(40px * var(--device-pixel-ratio));
                margin-bottom: calc(15px * var(--device-pixel-ratio));
                & .title {
                    font: 700 calc(22px * var(--device-pixel-ratio))/calc(20px * var(--device-pixel-ratio)) "SF Pro Text Light";
                    letter-spacing: calc(1.28px * var(--device-pixel-ratio));
                    padding: calc(10px * var(--device-pixel-ratio)) calc(10px * var(--device-pixel-ratio));
                }
            }
        }
        &-svg {
            &.smallBoard {
                display: none;
            }
        }
        .book-show-bottom {
            .player-8-book {
                left: 6.5%;
            }
            .player-9-book {
                left: 30%;
            }
            .player-10-book {
                right: 34.5%;
            }
            .player-11-book {
                right: 7%;
            }
        }
    }
}

.tab {
    .board {
        order: 1;
        width: calc(710px * var(--device-pixel-ratio));
        margin: 0 auto;
        height: calc(160px * var(--device-pixel-ratio));
        &-info {
            &-sides {
                height: calc(50px * var(--device-pixel-ratio));
                margin-bottom: calc(20px * var(--device-pixel-ratio));
                & .title {
                    font: 700 calc(25px * var(--device-pixel-ratio))/calc(30px * var(--device-pixel-ratio)) "SF Pro Text Light";
                    letter-spacing: calc(1.28px * var(--device-pixel-ratio));
                    padding: calc(5px * var(--device-pixel-ratio)) calc(10px * var(--device-pixel-ratio));
                }
            }
        }
        &-svg {
            &.smallBoard {
                display: none;
            }
        }
        .book-show-bottom {
            .player-8-book {
                left: 6.5%;
            }
            .player-9-book {
                left: 30%;
            }
            .player-10-book {
                right: 34.5%;
            }
            .player-11-book {
                right: 7%;
            }
        }
    }
    .board.board-32 {
        width: calc(678px * var(--device-pixel-ratio));
        height: calc(100px * var(--device-pixel-ratio));
        margin: 0 calc(50px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio)) calc(50px * var(--device-pixel-ratio));
    }
}

@media only screen and (max-width: 540px) {
    .board {
        width: 89.066vw;
        order: 1;
        margin: 0 auto;
        height: auto;
        &-info {
            &.threeD {
                transform: rotate3d(0, 0, 0, 0deg);
            }
            &-sides {
                margin-bottom: 1.19vh;
                height: 2.998vh;
                & .title {
                    font: 900 4.53vw/2.998vh "SF Pro Text Light";
                    letter-spacing: 0.181vw;
                    background: transparent;
                    border: transparent;
                    padding: 0;
                }
            }
        }
        &-svg {
            &.smallBoard {
                display: block;
            }
            &.largeBoard {
                display: none;
            }
        }
        .book-show-bottom {
            .player-8-book {
                border-radius: 8px;
                bottom: -5px;
                left: 0;
                min-width: calc(60px * var(--device-pixel-ratio));
            }
            .player-9-book {
                border-radius: 8px;
                bottom: -5px;
                left: 26.5%;
                min-width: calc(60px * var(--device-pixel-ratio));
            }
            .player-10-book {
                border-radius: 8px;
                bottom: -5px;
                right: 26.5%;
                min-width: calc(60px * var(--device-pixel-ratio));
            }
            .player-11-book {
                border-radius: 8px;
                bottom: -5px;
                right: 0;
                min-width: calc(60px * var(--device-pixel-ratio));
            }
        }
    }
    .board.board-32 {
        width: calc(300px * var(--device-pixel-ratio));
        height: calc(100px * var(--device-pixel-ratio));
        margin: 0 auto;
    }
}

@media only screen and (max-width: 374px) {
    .board.board-32 {
        width: calc(250px * var(--device-pixel-ratio));
        height: calc(85px * var(--device-pixel-ratio));
        .book-show-bottom {
            .player-8-book {
                min-width: calc(50px * var(--device-pixel-ratio));
                padding: calc(4px * var(--device-pixel-ratio)) calc(10px * var(--device-pixel-ratio));
            }
            .player-9-book {
                min-width: calc(50px * var(--device-pixel-ratio));
                padding: calc(4px * var(--device-pixel-ratio)) calc(10px * var(--device-pixel-ratio));
            }
            .player-10-book {
                min-width: calc(50px * var(--device-pixel-ratio));
                padding: calc(4px * var(--device-pixel-ratio)) calc(10px * var(--device-pixel-ratio));
            }
            .player-11-book {
                min-width: calc(50px * var(--device-pixel-ratio));
                padding: calc(4px * var(--device-pixel-ratio)) calc(10px * var(--device-pixel-ratio));
            }
        }
    }
}