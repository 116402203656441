.connect-message {
  background-image:  url("@assets/img/noInternetBg.svg"), radial-gradient(closest-side at 50% 50%, rgba(#1D1B2D, 0.9) 0%, rgba(#000000, 0.9) 100%);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 15;
  align-content: center;
  justify-content: center;
  & .icon-box {
    width: calc(58px * var(--device-pixel-ratio));
    margin-bottom: calc(36px * var(--device-pixel-ratio));
  }
  & .info {
    text-align: center;
    width: 100%;
    & .title {
      text-align: center;
      font: 900  calc(39px * var(--device-pixel-ratio))/ calc(46px * var(--device-pixel-ratio)) "SF Pro Text Light";
      letter-spacing: calc(1.56px * var(--device-pixel-ratio));
      color: #EAEAEC;
      text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $main-text-shadow-color;
      width: fit-content;
      margin: auto;
      padding-bottom: calc(20px * var(--device-pixel-ratio));
    }
    & .description {
      text-align: center;
      font: 900 calc(20px * var(--device-pixel-ratio))/calc(34px * var(--device-pixel-ratio)) "SF Pro Text Light";
      letter-spacing: calc(0.8px * var(--device-pixel-ratio));
      color: #EAEAEC;
      text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $main-text-shadow-color;
      width: 100%;
      margin: auto;
      padding-bottom: calc(44px * var(--device-pixel-ratio));
    }
    & .popup-btn {
      margin: auto;
    }
  }
}

.smallPc {
  .connect-message {
  & .icon-box {
    width: calc(50px * var(--device-pixel-ratio));
    margin-bottom: calc(32px * var(--device-pixel-ratio));
  }

  & .info {
    & .title {
      font: 900 calc(30px * var(--device-pixel-ratio))/calc(36px * var(--device-pixel-ratio)) "SF Pro Text Light";
      letter-spacing: calc(1.2px * var(--device-pixel-ratio));
      padding-bottom: calc(24px * var(--device-pixel-ratio));
    }

    & .description {
      font: 900 calc(16px * var(--device-pixel-ratio))/calc(28px * var(--device-pixel-ratio)) "SF Pro Text Light";
      letter-spacing: calc(0.64px * var(--device-pixel-ratio));
      padding-bottom: calc(40px * var(--device-pixel-ratio));
    }
  }
}
}

.tab {
  .connect-message {
  & .icon-box {
    width: calc(50px * var(--device-pixel-ratio));
    margin-bottom: calc(32px * var(--device-pixel-ratio));
  }

  & .info {
    & .title {
      font: 900 calc(30px * var(--device-pixel-ratio))/calc(36px * var(--device-pixel-ratio)) "SF Pro Text Light";
      letter-spacing: calc(1.2px * var(--device-pixel-ratio));
      padding-bottom: calc(24px * var(--device-pixel-ratio));
    }

    & .description {
      font: 900 calc(16px * var(--device-pixel-ratio))/calc(28px * var(--device-pixel-ratio)) "SF Pro Text Light";
      letter-spacing: calc(0.64px * var(--device-pixel-ratio));
      padding-bottom: calc(40px * var(--device-pixel-ratio));
    }
  }
}
}

@media only screen and (max-width: 540px) {
  .connect-message {
  & .icon-box {
    width: 10.66vw;
    margin-bottom: 3.598vh;
  }

  & .info {
    & .title {
      font: 900 6.13vw/4.197vh "SF Pro Text Light";
      letter-spacing: 0.245vw;
      text-shadow: 1px 1px 2px $main-text-shadow-color;
      padding-bottom: 2.998vh;
    }

    & .description {
      font: 900 3.2vw/2.998vh "SF Pro Text Light";
      letter-spacing: 0.128vw;
      text-shadow: 1px 1px 2px $main-text-shadow-color;
      padding-bottom: 5.997vh;
    }
  }
}
}