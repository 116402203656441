.template-button-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  z-index: 1;
  & .template-button-item {
    width: calc(48px * var(--device-pixel-ratio));
    height: calc(48px * var(--device-pixel-ratio));
    border-radius: 50%;
    background: rgba(29, 27, 45, 0.9) 0 0 no-repeat padding-box;
    border: 1px solid rgba(135, 135, 148, 0.5);
    display: block;
    &:not(.hybrid-item) {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    
    &.mobile-item {
      display: none;
      @media only screen and (max-width: 768px) {
        display: block;
      }
    }
    &:not(:first-child){
      margin-left: calc(24px * var(--device-pixel-ratio));
    }
    cursor: pointer;
    &:hover {
      background: rgba(84, 104, 126, 0.4) 0 0  no-repeat padding-box;
      border: 1px solid rgba(135, 135, 148, 0.5);
    }
    &:active, &.active {
      background: rgba(84, 104, 126, 0.9) 0 0 no-repeat padding-box;
      border: 1px solid rgba(135, 135, 148, 1);
    }
    &.disabled {
      user-select: none;
      pointer-events: none;
      opacity: 0.4;
      & .disabled {
        opacity: 0.4;
      }
    }
  }
}
