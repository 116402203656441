.user-center-info {
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(29, 27, 45, 0.7);
    .message {
        padding: 24px;
        background: rgba(29, 27, 45, 0.9) 0% 0% no-repeat padding-box;
        border: 1px solid rgba(135, 135, 148, 0.5);
        border-radius: 8px;
        text-align: center;
        &-icon {
            width: 40px;
            height: 40px;
        }
        &-description {
            text-transform: lowercase;
            text-align: center;
            font: normal normal 12px/20px 'SF Pro Text Bold';
            letter-spacing: 0.48px;
            color: rgba(234, 234, 236, 1);
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
            &::first-letter {
                text-transform: uppercase;
            }
        }
    }
}

@media (orientation: landscape) {
    body.mobile-only:not(.local) {
        .user-center-info {
            &.rotate-device {
                display: flex;
            }
            &.tap-full-screen {
                display: none;
            }
        }
    }
  }
  
@media (orientation: portrait) {
    body.mobile-only {
        .user-center-info {
            &.rotate-device {
                display: none;
            }
            &.tap-full-screen {
                display: flex;
            }
        }
        &.ios {
            .user-center-info {
                &.tap-full-screen {
                    display: none;
                }
            }
        }
    }
}