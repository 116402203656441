.hide-tool-bar-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,.8);
    z-index: 999999999;
    display: none;
    opacity: 0;
    transition: opacity .3s;
    pointer-events: none;
    &.show {
        opacity: 1;
        pointer-events: all;
    }
}

.hide-tool-bar-close-button {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    top: 1.2rem;
    right: 0.5rem;
    z-index: 100;
    svg {
        width: 100%;
        height: 100%;
    }
}

.hide-tool-bar-container {
    display: grid;
    grid-template-rows: auto 11fr;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 500;
    background-color: #272727;
}

.hide-tool-bar-head {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.6rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.5);
}

.hide-tool-bar-head-text {
    flex: 1;
    font-size: 1rem;
    line-height: 1.8rem;
    color: #fff;
    margin-right: 2rem;
}

.hide-tool-bar-close {
    flex: 0 0 auto;
    font-size: 1.6rem;
    color: #fff;
}

.hide-tool-bar-content {
    display: flex;
    width: 100%;
    padding: 1rem;
}

.hide-tool-bar-img, .hide-tool-bar-img-delay {
    display: block;
    position: absolute;
    width: 100%;
    max-width: 32rem;
    max-height: 40rem;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: contain;
    margin: auto;
}

.hide-tool-bar-img {
    animation: hide-toolbar-img 8s ease-in-out infinite;
}

.hide-tool-bar-img-delay {
    animation: hide-toolbar-img-delay 8s ease-in-out infinite;
}

.ios.mobile-only {
    .hide-tool-bar-wrapper {
        display: block;
    }
}

@keyframes hide-toolbar-img {
    0% {
        opacity: 0;
        transform: scale(0.6);
    }
    10% {
        opacity: 1;
        transform: scale(1);
    }
    40% {
        opacity: 1;
        transform: scale(1);
    }
    50% {
        opacity: 0;
        transform: scale(0.6);
    }
    100% {
        opacity: 0;
        transform: scale(0.6);
    }
}

@keyframes hide-toolbar-img-delay {
    0% {
        opacity: 0;
        transform: scale(0.6);
    }
    50% {
        opacity: 0;
        transform: scale(0.6);
    }
    60% {
        opacity: 1;
        transform: scale(1);
    }
    90% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0.6);
    }
}