.modal {
    overflow: hidden;
    position: fixed;
    right: calc(20px * var(--device-pixel-ratio));
    top: calc(107px * var(--device-pixel-ratio));
    background: rgba(29, 27, 45, 0.9) 0 0 no-repeat padding-box;
    border: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
    border-radius: calc(8px * var(--device-pixel-ratio));
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    transition: opacity, ease-in-out 200ms;
    &-header {
        padding: calc(20px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio));
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        &-title {
            text-align: left;
            font: 900 calc(14px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Light";
            letter-spacing: calc(0.56px * var(--device-pixel-ratio));
            color: rgba(255, 255, 255, 1);
        }
        & .modal-header-control {
            width: fit-content;
            line-height: 0px;
            & button:not(:last-child) {
                margin-right: calc(8px * var(--device-pixel-ratio));
            }
        }
    }
    &-body {
        padding: 0 calc(20px * var(--device-pixel-ratio)) 0 calc(20px * var(--device-pixel-ratio));
    }
    &.history {
        width: calc(561px * var(--device-pixel-ratio));
        height: calc(100% - 127px * var(--device-pixel-ratio));
        & .modal-header {
            & .modal-header-title {
                font: calc(14px * var(--device-pixel-ratio))/calc(24px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                letter-spacing: calc(0.56px * var(--device-pixel-ratio));
                color: #EAEAEC;
            }
        }
    }
    &.rules {
        height: calc(954px * var(--device-pixel-ratio));
        width: calc(598px * var(--device-pixel-ratio));
    }
    &.show {
        opacity: 1;
        visibility: visible;
    }
    & .title {
        padding: 0 0 calc(20px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio));
        text-align: left;
        font: calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Medium";
        letter-spacing: calc(0.56px * var(--device-pixel-ratio));
        color: #FFFFFF;
    }
}

.modal.game-last-history {
    position: fixed;
    bottom: 250px;
    left: 0;
    width: auto;
    right: unset;
    top: unset;
    .modal-header {
        display: none;
    }
}

.middlePc {
    .modal {
        top: calc(83px * var(--device-pixel-ratio));
        &-header {
            padding: calc(16px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio));
        }
        &.rules {
            width: calc(640px * var(--device-pixel-ratio));
            height: calc(681px * var(--device-pixel-ratio));
            & .modal-body {
                height: calc(590px * var(--device-pixel-ratio));
                overflow-y: auto;
                margin-right: calc(20px * var(--device-pixel-ratio));
            }
        }
        &.history {
            width: calc(553px * var(--device-pixel-ratio));
            height: calc(100% - 99px * var(--device-pixel-ratio));
        }
    }
}

.smallPc {
    .modal {
        top: calc(83px * var(--device-pixel-ratio));
        &-header {
            padding: calc(16px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio));
        }
        &.rules {
            width: calc(770px * var(--device-pixel-ratio));
            height: calc(690px * var(--device-pixel-ratio));
            & .modal-body {
                height: calc(620px * var(--device-pixel-ratio));
                overflow-y: auto;
                margin-right: calc(20px * var(--device-pixel-ratio));
            }
        }
        &.history {
            width: calc(553px * var(--device-pixel-ratio));
            height: calc(100% - 107px * var(--device-pixel-ratio));
        }
    }
}

.tab {
    .modal {
        top: calc(142px * var(--device-pixel-ratio));
        &-header {
            padding: calc(16px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio));
        }
        &.rules {
            width: calc(736px * var(--device-pixel-ratio)) !important;
            height: calc(100% - 145px * var(--device-pixel-ratio));
            & .modal-body {
                height: auto;
                overflow-y: auto;
                margin-right: calc(20px * var(--device-pixel-ratio));
            }
        }
        &.history {
            width: calc(553px * var(--device-pixel-ratio));
            height: calc(100% - 145px * var(--device-pixel-ratio));
        }
    }
}

@media only screen and (max-width: 540px) {
    .modal {
        top: 15.69vh;
        & .modal-header {
            padding: 16px 16px 16px 16px;
            &-control {
                & .icon {
                    width: 24px;
                }
            }
        }
        &.history {
            width: 97.86vw !important;
            height: calc(100% - 15.19vh);
            max-height: 82vh;
            left: 0;
            right: 0;
            margin: auto;
        }
        &.right-menu {
            top: 16.19vh;
            width: 230px;
            right: 12px;
        }
        &.rules {
            width: 96vw !important;
            height: 82vh;
            right: 0;
            left: 0;
            margin: auto;
            & .modal-body {
                height: 70vh;
                overflow-y: auto;
                margin-right: 20px;
                overflow-x: hidden;
            }
        }
    }
}