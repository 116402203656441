.nominal {
  &-list {
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: calc(12px * var(--device-pixel-ratio));
    width: calc(350px * var(--device-pixel-ratio));
    height: calc(148px * var(--device-pixel-ratio));
    margin: auto;
    padding-bottom: calc(12px * var(--device-pixel-ratio));
    &-item {
      width: calc(60px * var(--device-pixel-ratio));
      height: calc(60px * var(--device-pixel-ratio));
      cursor: pointer;
      &.disabled {
        opacity: 0.4;
        cursor: unset;
      }
      &:not(.disabled):hover {
        & svg {
          & .nominal-bg {
            fill: rgba(84,104,126,0.9);
          }
        }
      }
      &:not(.disabled):active {
        & svg {
          & .nominal-bg {
            fill: rgba(84,104,126,0.9);
            stroke: #878794;
          }
        }
      }
    }
  }
}

.middlePc {
  .nominal {
    &-list {
      gap: calc(5px * var(--device-pixel-ratio));
      width: calc(206px * var(--device-pixel-ratio));
      height: calc(105px * var(--device-pixel-ratio));
      padding-bottom: calc(8px * var(--device-pixel-ratio));
      overflow-y: scroll;
      &-item {
        width: calc(44px * var(--device-pixel-ratio));
        height: calc(44px * var(--device-pixel-ratio));
      }
    }
  }
}

.smallPc {
  .nominal {
    &-list {
      gap: calc(5px * var(--device-pixel-ratio));
      width: calc(242px * var(--device-pixel-ratio));
      height: calc(106px * var(--device-pixel-ratio));
      padding-bottom: calc(8px * var(--device-pixel-ratio));
      &-item {
        width: calc(44px * var(--device-pixel-ratio));
        height: calc(44px * var(--device-pixel-ratio));
      }
    }
  }
}

.tab {
  .nominal {
    &-list {
      gap: calc(8px * var(--device-pixel-ratio));
      width: calc(224px * var(--device-pixel-ratio));
      height: calc(107px * var(--device-pixel-ratio));
      padding-bottom: calc(8px * var(--device-pixel-ratio));
      overflow-y: scroll;
      &-item {
        width: calc(44px * var(--device-pixel-ratio));
        height: calc(44px * var(--device-pixel-ratio));
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .nominal {
    &-list {
      gap: 2.13vw;
      width: 100%;
      height: 14.09vh;
      margin: auto;
      overflow-y: scroll;
      padding-bottom: 0.899vh;
      &-item {
        width: 9.6vw;
        max-width: 5.397vh;
        height: 5.397vh;
        max-height: 9.6vw;
      }
    }
  }
}