.user-won {
    display: none;
    position: absolute;
    width: calc(353px * var(--device-pixel-ratio));
    height: calc(307px * var(--device-pixel-ratio));
    left: 50%;
    z-index: 2;
    top: calc(286px * var(--device-pixel-ratio));
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity .5s;
    &.show {
        opacity: 1;
    }

    .price {
        position: absolute;
        text-align: center;
        font: 900 calc(50px * var(--device-pixel-ratio))/calc(60px * var(--device-pixel-ratio)) "SF Pro Text Bold";
        letter-spacing: calc(2px * var(--device-pixel-ratio));
        color: #FFCC00;
        text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
        left: 50%;
        transform: translateX(-50%);
        top: calc(164px * var(--device-pixel-ratio));
        z-index: 1;
    }
    .title {
        position: absolute;
        font: bold calc(23px * var(--device-pixel-ratio))/calc(28px * var(--device-pixel-ratio)) "SF Pro Text Bold";
        letter-spacing: calc(0.92px * var(--device-pixel-ratio));
        color: #FFFFFF;
        text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
        left: 50%;
        transform: translateX(-50%);
        top: 21%;
        z-index: 1;
    }
    svg {
        position: absolute;
        &.background {
            width: calc(353px * var(--device-pixel-ratio));
            height: calc(307px * var(--device-pixel-ratio));
        }
        &.corona {
            width: calc(55px * var(--device-pixel-ratio));
            height: calc(55px * var(--device-pixel-ratio));
            left: 50%;
            top: 47%;
            transform: translate(-50%, -50%);
        }
    }
}

.smallPc {
    .user-won {
        display: none;
        width: calc(285px * var(--device-pixel-ratio));
        height: calc(252px * var(--device-pixel-ratio));
        top: calc(185px * var(--device-pixel-ratio));
    
        .price {
            font: 900 calc(45px * var(--device-pixel-ratio))/calc(54px * var(--device-pixel-ratio)) "SF Pro Text Bold";
            letter-spacing: calc(1.8px * var(--device-pixel-ratio));
            top: 51%;
        }
        .title {
            font: bold calc(20px * var(--device-pixel-ratio))/calc(24px * var(--device-pixel-ratio)) "SF Pro Text Bold";
            letter-spacing: calc(0.8px * var(--device-pixel-ratio));
            top: 22%;
        }
        svg {
            &.background {
                width: 100%;
                height: 100%;
            }
            &.corona {
                width: calc(47px * var(--device-pixel-ratio));
                top: 45%;
                height: calc(41px * var(--device-pixel-ratio));
            }
        }
    }
    
}

.tab {
    .user-won {
        display: none;
        width: calc(226px * var(--device-pixel-ratio));
        height: calc(226px * var(--device-pixel-ratio));
        top: calc(207px * var(--device-pixel-ratio));
    
        .price {
            font: bold calc(36px * var(--device-pixel-ratio))/calc(43px * var(--device-pixel-ratio)) "SF Pro Text Bold";
            letter-spacing: calc(1.44px * var(--device-pixel-ratio));
            top: 51.5%;
        }
        .title {
            font: bold calc(15px * var(--device-pixel-ratio))/calc(18px * var(--device-pixel-ratio)) "SF Pro Text Bold";
            letter-spacing: calc(0.6px * var(--device-pixel-ratio));
            top: calc(56px * var(--device-pixel-ratio));
        }
        svg {
            &.background {
                width: 100%;
                height: 100%;
            }
            &.corona {
                width: calc(33px * var(--device-pixel-ratio));
                height: calc(33px * var(--device-pixel-ratio));
                top: 46%;
            }
        }
    }
}

@media only screen and (max-width: 540px)  {
    .user-won {
        display: none;
        width: calc(150px * var(--device-pixel-ratio));
        height: calc(127px * var(--device-pixel-ratio));
        top: 12%;
    
        .price {
            font: 900 calc(22px * var(--device-pixel-ratio))/calc(26px * var(--device-pixel-ratio)) "SF Pro Text Bold";
            letter-spacing: calc(0.88px * var(--device-pixel-ratio));
            top: 50%;
        }
        .title {
            font: calc(10px * var(--device-pixel-ratio))/calc(12px * var(--device-pixel-ratio)) "SF Pro Text Bold";
            letter-spacing: calc(0.4px * var(--device-pixel-ratio));
        }
        svg {
            &.background {
                width: calc(150px * var(--device-pixel-ratio));
                height: calc(127px * var(--device-pixel-ratio));
            }
            &.corona {
                width: calc(25px * var(--device-pixel-ratio));
                height: calc(21px * var(--device-pixel-ratio));
                top: 43%;
            }
        }
    }
}