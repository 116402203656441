.cards {
    width: calc(330px * var(--device-pixel-ratio));
    height: calc(260px * var(--device-pixel-ratio));
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: fixed;
    top: calc(224px * var(--device-pixel-ratio));
    left: calc(0px * var(--device-pixel-ratio));
    background: transparent linear-gradient(180deg, #1D1B2DB3 0%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
    border: calc(1px * var(--device-pixel-ratio)) solid #87879480;
    border-left-color: transparent;
    border-radius: calc(0px * var(--device-pixel-ratio)) calc(8px * var(--device-pixel-ratio)) calc(8px * var(--device-pixel-ratio)) calc(0px * var(--device-pixel-ratio));
    padding: calc(15px * var(--device-pixel-ratio));
    &-element {
        margin-right: calc(15px * var(--device-pixel-ratio));
        border-bottom: 0;
        & .title {
            text-align: right;
            font: calc(15px * var(--device-pixel-ratio))/calc(18px * var(--device-pixel-ratio)) "SF Pro Text Medium";
            letter-spacing: calc(0.6px * var(--device-pixel-ratio));
            color: #FFFFFF;
            text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
            margin-bottom: calc(8px * var(--device-pixel-ratio));
            text-transform: uppercase;
        }
        &:nth-child(1) {
            padding-bottom: 0;
            border-bottom: 0;
        }
        &:nth-child(2) {
            padding-top: calc(20px * var(--device-pixel-ratio));
        }
        .cards-top-box {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            & .title {
                writing-mode: tb-rl;
                transform: rotate(-180deg);
                margin-bottom: 0;
                margin-right: calc(10px * var(--device-pixel-ratio));
                width: calc(20px * var(--device-pixel-ratio));
                height: calc(70px * var(--device-pixel-ratio));
                font: calc(14px * var(--device-pixel-ratio))/calc(18px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                text-transform: capitalize;
            }
            .cards-list {
                align-items: center;
                .cards-list-item {
                    display: flex;
                    align-items: flex-start;
                }
            }
        }
        .cards-middle-box {
            display: flex;
            justify-content: center;
            align-items: center;
            & .title {
                width: calc(20px * var(--device-pixel-ratio));
                margin-right: calc(10px * var(--device-pixel-ratio));
            }
            .cards-list {
                align-items: center;
                justify-content: center;
                .cards-list-item {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: center;
                    flex-direction: column;
                    height: auto;
                    margin-top: 0;
                }
            }
            .plus-cards {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                img {
                    width: auto;
                    height: 20px;
                    object-fit: cover;
                }
            }
        }
        .cards-bottom-box {
            display: flex;
            justify-content: center;
            align-items: center;
            & .title {
                writing-mode: tb-rl;
                transform: rotate(-180deg);
                margin-bottom: 0;
                margin-right: calc(10px * var(--device-pixel-ratio));
                width: calc(20px * var(--device-pixel-ratio));
                height: calc(85px * var(--device-pixel-ratio));
            }
            .cards-list {
                align-items: center;
                justify-content: center;
                .cards-list-item {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: center;
                    height: auto;
                    margin-top: 0;
                }
            }
            .plus-cards {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                img {
                    width: auto;
                    height: 20px;
                    object-fit: cover;
                }
            }
        }
    }
    &-list {
        list-style: none;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        flex-direction: row;
        &-item {
            width: calc(38px * var(--device-pixel-ratio));
            height: calc(50px * var(--device-pixel-ratio));
            &:not(:last-child) {
                margin-right: calc(10px * var(--device-pixel-ratio));
            }
            & .flip-card {
                width: 100%;
                height: 100%;
                perspective: calc(1000px * var(--device-pixel-ratio));
            }
            & .flip-card-inner {
                position: relative;
                width: 100%;
                height: 100%;
                transition: transform 0.6s;
                transform-style: preserve-3d;
            }
            & .flip-card.active .flip-card-inner {
                transform: rotateY(180deg);
            }
            & .flip-card-front,
            .flip-card-back {
                position: absolute;
                width: 100%;
                height: 100%;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
            & .flip-card-front,
            & .flip-card-back {
                & img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            & .flip-card-back {
                transform: rotateY(180deg);
                & .cardIcon {
                    height: 100%;
                    font-size: calc(46px * var(--device-pixel-ratio));
                    position: absolute;
                    top: 0;
                    box-shadow: none;
                    background: transparent;
                }
            }
            & .card_number_title {
                border: 1px solid #878794;
                border-radius: 4px;
                width: calc(36px * var(--device-pixel-ratio));
                height: calc(48px * var(--device-pixel-ratio));
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font: calc(17px * var(--device-pixel-ratio))/calc(22px * var(--device-pixel-ratio)) "SF Pro Text Bold";
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}

.middlePc {
    .cards {
        top: calc(160px * var(--device-pixel-ratio));
        width: calc(340px * var(--device-pixel-ratio));
        height: calc(204px * var(--device-pixel-ratio));
        padding: calc(10px * var(--device-pixel-ratio));
        &-element {
            & .title {
                font: bold calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Bold";
                letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                margin-bottom: calc(6px * var(--device-pixel-ratio));
            }
        }
        &-list {
            &-item {
                width: calc(34px * var(--device-pixel-ratio));
                height: calc(50px * var(--device-pixel-ratio));
                &:not(:last-child) {
                    margin-right: calc(8px * var(--device-pixel-ratio));
                }
                & .flip-card-back {
                    & .cardIcon {
                        font-size: calc(40px * var(--device-pixel-ratio));
                    }
                }
                .card_number_title {
                    width: calc(30px * var(--device-pixel-ratio));
                    height: calc(40px * var(--device-pixel-ratio));
                }
            }
        }
        .cards-top-box {
            .title {
                height: calc(49px * var(--device-pixel-ratio));
                font: calc(10px * var(--device-pixel-ratio))/calc(18px * var(--device-pixel-ratio)) "SF Pro Text Medium";
            }
        }
        .cards-bottom-box {
            margin-top: 0;
        }
    }
}

.smallPc {
    .cards {
        top: calc(224px * var(--device-pixel-ratio));
        width: calc(150px * var(--device-pixel-ratio));
        height: calc(254px * var(--device-pixel-ratio));
        padding: calc(16px * var(--device-pixel-ratio));
        &-element {
            & .title {
                font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                margin-bottom: calc(6px * var(--device-pixel-ratio));
            }
            &:nth-child(1) {
                padding-bottom: 0;
            }
            &:nth-child(2) {
                padding-top: 0;
            }
        }
        &-list {
            &-item {
                width: calc(34px * var(--device-pixel-ratio));
                height: calc(50px * var(--device-pixel-ratio));
                &:not(:last-child) {
                    margin-right: calc(8px * var(--device-pixel-ratio));
                }
                & .flip-card-back {
                    & .cardIcon {
                        font-size: calc(32px * var(--device-pixel-ratio));
                    }
                }
                .card_number_title {
                    width: calc(30px * var(--device-pixel-ratio));
                    height: calc(40px * var(--device-pixel-ratio));
                }
            }
        }
    }
    .cards {
        width: calc(340px * var(--device-pixel-ratio));
        padding: calc(10px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio));
        .cards-element {
            .cards-top-box {
                .title {
                    height: calc(60px * var(--device-pixel-ratio));
                    font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                }
            }
            .cards-middle-box {
                .cards-list {
                    .cards-list-item {
                        width: calc(34px * var(--device-pixel-ratio));
                        height: calc(50px * var(--device-pixel-ratio));
                    }
                }
            }
        }
    }
}

.tab {
    .cards {
        display: none;
    }
    .cards-mobile {
        position: unset;
        width: 240px;
        height: 96px;
        padding: 4px;
        .cards-element {
            width: 25%;
            margin: 0;
            align-items: center;
            .cards-top-box {
                margin-bottom: 2px;
            }
            .cards-bottom-box {
                margin-top: 2px;
            }
            .cards-middle-box {
                width: auto;
            }
        }
    }
}

@media only screen and (max-width: 540px) {
    .cards {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 20.689vh;
        width: 100vw;
        height: 60px;
        left: 0;
        background: transparent;
        border-color: transparent;
        padding: 0;
        &-element {
            width: 112px;
            height: 100%;
            background: transparent linear-gradient(180deg, #1D1B2DB3 0%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
            border: 1px solid #87879480;
            border-radius: 0px 6px 6px 0px;
            &:nth-child(1) {
                border-left-color: transparent;
                border-bottom: 0px;
                padding: 10px;
            }
            &:nth-child(2) {
                border-radius: 6px 0px 0px 6px;
                border-right-color: transparent;
                padding: 10px;
            }
            & .title {
                display: none;
            }
            .cards-middle-box {
                width: auto !important;
            }
        }
        &-list {
            &-item {
                width: 28px;
                height: 40px;
                &:not(:last-child) {
                    margin-right: calc(4px * var(--device-pixel-ratio));
                }
                & .flip-card-back {
                    & .cardIcon {
                        font-size: calc(40px * var(--device-pixel-ratio));
                    }
                }
            }
        }
    }
    .cards-mobile {
        width: 100px !important;
        height: 170px !important;
        // .cards-element {
        //     .cards-top-box {
        //         display: none;
        //     }
        // }
    }
}

@media only screen and (max-width: 374px) {
    .cards-mobile {
        width: 80px !important;
        height: 120px !important;
        .cards-middle-box {
            .cards-list {
                .cards-list-item {
                    .card_number_title {
                        width: calc(30px * var(--device-pixel-ratio));
                        height: calc(40px * var(--device-pixel-ratio));
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1279px) {
    .cards {
        display: none;
    }
    .cards-mobile {
        display: flex;
        flex-wrap: wrap;
        width: 112px;
        height: 196px;
        position: fixed;
        padding: 0;
        top: 90px;
        left: 0;
        background: transparent linear-gradient(180deg, #1D1B2DB3 0%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
        border: 1px solid #87879480;
        border-radius: 0px 8px 8px 0px;
        .cards-element {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            background: transparent;
            border: 0;
            padding: 0;
            width: 50%;
            margin: 0;
            height: auto;
            .cards-top-box {
                margin-bottom: 5px;
                .title {
                    font: calc(10px * var(--device-pixel-ratio))/calc(12px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                }
            }
            .cards-bottom-box {
                margin-top: 5px;
                .title {
                    font: calc(9px * var(--device-pixel-ratio))/calc(11px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                }
            }
            .title {
                display: block;
                transform: unset !important;
                height: auto !important;
                writing-mode: unset !important;
                margin: 0 !important;
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    .cards-mobile {
        display: none;
    }
}