* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    &:not(input) {
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

*,
html,
body {
    font-family: "SF Pro Text Light";
}

.fontcaptain,
.fontcaptain tspan {
    font-family: "American Captain";
}

p {
    margin: 0;
}

body {
    overflow: hidden;
    background-color: #1D1B2D;
    &.bg {
        background: repeating-linear-gradient(-40deg, rgba(29, 27, 45, 0) 0%, rgba(29, 27, 45, 0) 0%, rgba(29, 27, 45, 0.7) 1.175%, rgba(29, 27, 45, 0.7) 1.2%, rgba(29, 27, 45, 0.7) 1.225%, rgba(234, 234, 236, 0.16) 1.275%, rgba(29, 27, 45, 0.7) 1.325%, rgba(29, 27, 45, 0) 2.475%, rgba(29, 27, 45, 0) 2.5%), radial-gradient(closest-side at 50% 50%, #1D1B2D 0%, #000000 100%) 0% 0% no-repeat padding-box;
    }
}

#root {
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: hidden;
}

.mainContent {
    position: relative;
    transform-origin: left top;
}

.smallPc {
    & .mainContent,
    & .border-box {
        min-width: calc(1280px * var(--device-pixel-ratio));
        min-height: calc(800px * var(--device-pixel-ratio));
        width: calc(1280px * var(--device-pixel-ratio));
        height: calc(800px * var(--device-pixel-ratio));
    }
}

.pc {
    & .mainContent,
    & .border-box {
        min-width: calc(1920px * var(--device-pixel-ratio));
        min-height: calc(1080px * var(--device-pixel-ratio));
        width: calc(1920px * var(--device-pixel-ratio));
        height: calc(1080px * var(--device-pixel-ratio));
    }
}

.middlePc {
    & .mainContent,
    & .border-box {
        min-width: calc(1470px * var(--device-pixel-ratio));
        min-height: calc(780px * var(--device-pixel-ratio));
        width: calc(1470px * var(--device-pixel-ratio));
        height: calc(780px * var(--device-pixel-ratio));
    }
}

.tab {
    & .mainContent,
    & .border-box {
        min-width: calc(768px * var(--device-pixel-ratio));
        min-height: calc(1024px * var(--device-pixel-ratio));
        width: calc(768px * var(--device-pixel-ratio));
        height: calc(1024px * var(--device-pixel-ratio));
    }
}

.smallPc,
.pc,
.tab,
.middlePc {
    & .mainContent {
        overflow: hidden;
    }
}

.w-100 {
    width: 100%;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 calc(6px * var(--device-pixel-ratio)) transparent;
    border-radius: calc(4px * var(--device-pixel-ratio));
    background-color: transparent;
}

::-webkit-scrollbar {
    width: calc(4px * var(--device-pixel-ratio));
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: calc(4px * var(--device-pixel-ratio));
    -webkit-box-shadow: inset 0 0 calc(6px * var(--device-pixel-ratio)) rgba(0, 0, 0, .1);
    background-color: #878794;
}

.cardIcon {
    line-height: 1;
    background-color: #eaeaec;
    border-radius: 10%;
    box-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
    width: 100%;
    height: 100%;
    font-size: calc(55px * var(--device-pixel-ratio));
    position: relative;
    display: block;
}

.cardIcon:before {
    font-family: iconfont !important;
    font-style: normal;
    font-weight: normal !important;
    vertical-align: top;
    position: absolute;
    top: 50%;
    left: 50%;
    // width: 100%;
    // height: 100%;
    transform: translate(-50%, -50%);
}

.icon-undefined:before {
    content: "";
}

.icon-10spades:before {
    color: rgb(0 0 0);
}

.icon-10clubs:before {
    color: rgb(0 0 0);
}

.icon-10hearts:before {
    color: rgb(230, 19, 10);
}

.icon-10diamonds:before {
    color: rgb(230, 19, 10);
}

.icon-1spades:before {
    color: rgb(0 0 0);
}

.icon-1clubs:before {
    color: rgb(0 0 0);
}

.icon-1hearts:before {
    color: rgb(230, 19, 10);
}

.icon-1diamonds:before {
    color: rgb(230, 19, 10);
}

.icon-2spades:before {
    color: rgb(0 0 0);
}

.icon-2clubs:before {
    color: rgb(0 0 0);
}

.icon-2hearts:before {
    color: rgb(230, 19, 10);
}

.icon-2diamonds:before {
    color: rgb(230, 19, 10);
}

.icon-3spades:before {
    color: rgb(0 0 0);
}

.icon-3clubs:before {
    color: rgb(0 0 0);
}

.icon-3hearts:before {
    color: rgb(230, 19, 10);
}

.icon-3diamonds:before {
    color: rgb(230, 19, 10);
}

.icon-4spades:before {
    color: rgb(0 0 0);
}

.icon-4clubs:before {
    color: rgb(0 0 0);
}

.icon-4hearts:before {
    color: rgb(230, 19, 10);
}

.icon-4diamonds:before {
    color: rgb(230, 19, 10);
}

.icon-5spades:before {
    color: rgb(0 0 0);
}

.icon-5clubs:before {
    color: rgb(0 0 0);
}

.icon-5hearts:before {
    color: rgb(230, 19, 10);
}

.icon-5diamonds:before {
    color: rgb(230, 19, 10);
}

.icon-6spades:before {
    color: rgb(0 0 0);
}

.icon-6clubs:before {
    color: rgb(0 0 0);
}

.icon-6hearts:before {
    color: rgb(230, 19, 10);
}

.icon-6diamonds:before {
    color: rgb(230, 19, 10);
}

.icon-7spades:before {
    color: rgb(0 0 0);
}

.icon-7clubs:before {
    color: rgb(0 0 0);
}

.icon-7hearts:before {
    color: rgb(230, 19, 10);
}

.icon-7diamonds:before {
    color: rgb(230, 19, 10);
}

.icon-8spades:before {
    color: rgb(0 0 0);
}

.icon-8clubs:before {
    color: rgb(0 0 0);
}

.icon-8hearts:before {
    color: rgb(230, 19, 10);
}

.icon-8diamonds:before {
    color: rgb(230, 19, 10);
}

.icon-9spades:before {
    color: rgb(0 0 0);
}

.icon-9clubs:before {
    color: rgb(0 0 0);
}

.icon-9hearts:before {
    color: rgb(230, 19, 10);
}

.icon-9diamonds:before {
    color: rgb(230, 19, 10);
}

.icon-Aspades:before {
    color: rgb(0 0 0);
}

.icon-Aclubs:before {
    color: rgb(0 0 0);
}

.icon-Ahearts:before {
    color: rgb(230, 19, 10);
}

.icon-Adiamonds:before {
    color: rgb(230, 19, 10);
}

.icon-Jspades:before {
    color: rgb(0 0 0);
}

.icon-Jclubs:before {
    color: rgb(0 0 0);
}

.icon-Jhearts:before {
    color: rgb(230, 19, 10);
}

.icon-Jdiamonds:before {
    color: rgb(230, 19, 10);
}

.icon-Kspades:before {
    color: rgb(0 0 0);
}

.icon-Kclubs:before {
    color: rgb(0 0 0);
}

.icon-Khearts:before {
    color: rgb(230, 19, 10);
}

.icon-Kdiamonds:before {
    color: rgb(230, 19, 10);
}

.icon-Qspades:before {
    color: rgb(0 0 0);
}

.icon-Qclubs:before {
    color: rgb(0 0 0);
}

.icon-Qhearts:before {
    color: rgb(230, 19, 10);
}

.icon-Qdiamonds:before {
    color: rgb(230, 19, 10);
}

.tablet,
.mobile-only {
    & header {
        & .game-controller {
            & .settings-box {
                & .template-button-item:not(.music) {
                    & .tooltip-wrapper {
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 540px) {
     ::-webkit-scrollbar {
        width: 4px;
    }
     ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
     ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px transparent;
        border-radius: 4px;
    }
}